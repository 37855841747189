export default {
  inputs: {
    productType: "RESIDENTIAL",
    purpose: "PURCHASE",
    repaymentType: "HPP",
    propertyValue: 200000,
    financeRequired: 100000,
    fTV: 0.5,
    term: 360,
  },
  selectedProduct: {
    name: "",
    email: "",
    phone: "",
    callRequest: null,
  },
  products: [
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 2 year fixed, 85% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.85,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0799,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 2 year fixed, 80% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0779,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: null,
      lenderName: null,
      productName: "StrideUp HPP, 2 year fixed, 75% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.75,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0699,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: null,
      lenderName: null,
      productName: "StrideUp HPP, 2 year fixed, 65% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0679,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 2 year fixed, 85% FTV (refinance)",
      purpose: "REFINANCE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.85,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0789,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 995.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 2 year fixed, 80% FTV (refinance)",
      purpose: "REFINANCE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0784,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 995.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 5 year fixed, 85% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0799,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 5 year fixed, 80% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0779,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 5 year fixed, 75% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.75,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0724,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 5 year fixed, 65% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.65,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0714,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 5 year fixed, 85% FTV (refinance)",
      purpose: "REFINANCE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0749,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 995.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 5 year fixed, 80% FTV (refinance)",
      purpose: "REFINANCE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0744,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 995.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 10 year fixed, 85% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.85,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0739,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 10 year fixed, 80% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0709,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 10 year fixed, 75% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0649,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 10 year fixed, 65% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0629,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 1249.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 10 year fixed, 85% FTV (refinance)",
      purpose: "REFINANCE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.85,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0724,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 995.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "STRIDE_UP",
      lenderName: "StrideUp",
      productName: "StrideUp HPP, 10 year fixed, 80% FTV (refinance)",
      purpose: "REFINANCE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 120,
      initialPeriodLimit: null,
      initialRate: 0.0719,
      initialRateType: "FIXED",
      minTerm: 36,
      maxTerm: 480,
      variableRate: 0.0824,
      fixedAmountFee: 995.0,
      variableFee: null,
      minFinance: 50000,
      maxFinance: 750000,
      minPropertyValue: 100000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed Green Product 65% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0649,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed Green Product 80% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0669,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed Green Product 90% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0719,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed Green Product 65% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0685,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed Green Product 80% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0695,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed Green Product 90% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0739,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed, 65% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0659,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed, 80% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0679,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed, 90% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0729,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed, 65% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0695,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed, 80% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0705,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 499.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed, 90% FTV",
      purpose: "PURCHASE",
      productType: "RESIDENTIAL",
      repaymentType: "HPP",
      fTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0749,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: 999.0,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 500000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed Green Product 65% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0714,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed Green Product 80% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0734,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed Green Product 65% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.075,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed Green Product 80% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0764,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed, 65% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.0724,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 2 years fixed, 80% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0744,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed, 65% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.65,
      initialPeriodMonths: 24,
      initialPeriodLimit: null,
      initialRate: 0.076,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
    {
      lender: "GATEHOUSE BANK",
      lenderName: "Gatehouse Bank",
      productName: "Gatehouse Bank, 5 years fixed, 80% FTV",
      purpose: "PURCHASE",
      productType: "BUY TO LET",
      repaymentType: "BTL",
      fTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0774,
      initialRateType: "FIXED",
      minTerm: 60,
      maxTerm: 420,
      variableRate: 0.07,
      fixedAmountFee: null,
      variableFee: null,
      minFinance: 75000,
      maxFinance: 5000000,
      minPropertyValue: 115000,
      location: "ENGLAND_WALES",
    },
  ],
};
